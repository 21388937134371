
































import { Component, Vue, Mixins } from 'vue-property-decorator';
import { ProfileFilter } from 'client-website-ts-library/filters';
import { Profile, WebsiteProfileDisplayType } from 'client-website-ts-library/types';
import { API } from 'client-website-ts-library/services';
import { View } from 'client-website-ts-library/plugins';

import ProfileCards from '../components/ProfileCards.vue';
import Loader from '../components/UI/Loader.vue';

@Component({
  components: {
    ProfileCards,
    Loader,
  },
})
export default class AboutUs extends Mixins(View) {
  private profiles: Profile[] = [];

  mounted() {
    const filter = new ProfileFilter();

    API.Profiles.Search(filter).then((profiles) => {
      this.profiles = profiles;
    });
  }
}
